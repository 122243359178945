import React, { useEffect } from 'react'
import styled from 'styled-components'
import { createBrowserHistory } from 'history'
import { Router, Switch, Route } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { historyChanged, cookiesSetAnalyticsId } from 'issy-components'

import config from './config'
import pageConfigs from './page-configs'
import { withAppData } from './hoc'
import Home from './Home'
import About from './About'

const AppContainer = styled.div`
    height: 100vh;
    width: 100%;
`

const history = createBrowserHistory()

const App = ({ appData }) => {
    const dispatch = useDispatch()
    useEffect(() => {
        history.listen((location) => dispatch(historyChanged(location)))
        dispatch(cookiesSetAnalyticsId(config.GOOGLE_ANALYTICS_ID))
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const solutionsData = {}
    appData.solution.forEach(
        (solutionData) => (solutionsData[solutionData.name] = solutionData)
    )

    const routeElems = appData.solution.map((pageData) => {
        const pageId = pageData.name
        const pageConfig = pageConfigs[pageId]
        if (!pageConfig) {
            console.error(`Page config for "${pageId}" not found.`)
            return null
        }
        const PageComponent = pageConfig.component
        return (
            <Route path={pageConfig.url} key={pageId}>
                <PageComponent
                    solutionData={solutionsData[pageId]}
                    pageConfig={pageConfig}
                />
            </Route>
        )
    })

    return (
        <AppContainer>
            <Router history={history}>
                <Switch>
                    {routeElems}
                    <Route path={`${config.ROOT_URL}/about`}>
                        <About />
                    </Route>
                    <Route path={`/${config.ROOT_URL}`}>
                        <Home />
                    </Route>
                </Switch>
            </Router>
        </AppContainer>
    )
}

export default withAppData(App, ['solution'])
